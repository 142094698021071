import React from "react";
import img from "../Components/Assets/Images/brand-icon.webp";

const Contact = () => {
	return (
		<>
			<div
				id="contact-page"
				class="container flex-column d-flex justify-content-around align-items-center mb-4"
			>
				<h2 class="mb-2 mx-2 bg-body-secondary w-100 px-4 py-1">beaoxogo@gmail.com</h2>
				<div class="d-flex justify-content-center align-items-center contact-center p-0 p-lg-5">
					<div className="col-lg-6 ">
						<img
							rel="preload"
							onClick={window.scrollTo(0, 0)}
							className=" p-0 px-lg-5 object-fit-cover contact-img-logo"
							src={img}
							alt=""
						/>
					</div>
					<div class="col-lg-6 mx-2">
						<form action="https://getform.io/f/ajjjdrqa" method="POST" className="contact-f">
							<div class="row g-3 p-lg-0 p-4 pt-0 pb-0">
								<div class="col-md-6">
									<label for="givenname" class="form-label">
										Your Name
									</label>
									<input type="text" class="form-control" id="givenname" name="givenname" required />
								</div>
								<div class="col-md-6">
									<label for="your-surname" class="form-label">
										Your Surname
									</label>
									<input type="text" class="form-control" id="your-surname" name="your-surname" required />
								</div>
								<div class="col-md-6">
									<label for="email" class="form-label">
										Your Email
									</label>
									<input type="email" class="form-control" id="email" name="email" required />
								</div>
								<div class="col-md-6">
									<label for="your-subject" class="form-label">
										Your Subject
									</label>
									<input required type="text" class="form-control" id="your-subject" name="your-subject" />
								</div>
								<div class="col-12">
									<label for="message" class="form-label">
										Your Message
									</label>
									<textarea class="form-control" id="message" name="message" rows="7" required></textarea>
								</div>
								<div class="col-12">
									<div class="row">
										<div class="col-md-6 w-100">
											<button
												data-res="<?php echo $sum; ?>"
												type="submit"
												class="btn btn-dark w-100 fw-bold submit-btn"
											>
												Send
											</button>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default Contact;
