import React, { useState } from "react";
import "../Enquire/Enquire.css";
import { Link } from "react-router-dom";

const Enquire = (props) => {
	const { enquireData } = props;

	const [textAreaContent, setTextAreaContent] = useState("");
	const listItems = [
		"What is the best price you can offer?",
		"What is the shipping cost?",
		"Do you support customization?",
		"How long will it take to ship to my country?",
		"What is the MOQ for this product?",
	];

	const handleClick = (item) => {
		// Append the clicked <li> item's text to the textarea content
		setTextAreaContent((prevContent) => prevContent + item + "\n");
	};

	const handleChange = (e) => {
		setTextAreaContent(e.target.value);
	};

	return (
		<>
			<div className="Enquire">
				<div className=" enquire-head d-flex justify-content-center align-items-center mt-0 mt-lg-3 bg-body-secondary mb-4 ">
					<div>
						<Link to={`/ProductDetail/${enquireData.id}`}>
							<img
								onClick={window.scrollTo(0, 0)}
								className="rounded-4 object-fit-cover"
								height={150}
								width={150}
								src={enquireData.imageUrl[0]}
								alt=""
							/>
						</Link>
					</div>
					<div className="p-2 p-lg-5 mb-2">
						<h4>{enquireData.name}</h4>
						<h4>{enquireData.price}</h4>
						<h4>{enquireData.minimunOrderQuantity}</h4>
						<h4>{enquireData.productionLeadTime}</h4>
					</div>
				</div>
				<div className="enquire-form container">
					<div class="container">
						<h4 class="mb-3">Contact Supplier</h4>
						<form action="https://getform.io/f/ajjjdrqa" method="POST" class="needs-validation">
							<input type="hidden" name="product_id" value={enquireData.id} />
							<input type="hidden" name="product_name" value={enquireData.name} />
							<input type="hidden" name="product_image" value={enquireData.imageUrl[0]} />

							<div class="row g-3 enquire-inputs">
								<div class="col-sm-6">
									<label for="lastName" class="form-label">
										Given Name
									</label>
									<input
										name="givenname"
										type="text"
										class="form-control"
										id="name"
										placeholder=""
										required
									/>
									<div class="invalid-feedback">Valid last name is required.</div>
								</div>

								<div class="col-sm-6">
									<label for="lastName" class="form-label">
										Surname
									</label>
									<input
										name="lastname"
										type="text"
										class="form-control"
										id="surname"
										placeholder=""
										required
									/>
									<div class="invalid-feedback">Valid last name is required.</div>
								</div>

								<div class="col-sm-6">
									<label for="lastName" class="form-label">
										Company Name
									</label>
									<input
										name="company"
										type="text"
										class="form-control"
										id="company"
										placeholder=""
										required
									/>
									<div class="invalid-feedback">Valid last name is required.</div>
								</div>

								<div class="col-sm-6">
									<label for="lastName" class="form-label">
										Country / Region
									</label>
									<input
										name="country"
										type="text"
										class="form-control"
										id="country"
										placeholder=""
										required
									/>
									<div class="invalid-feedback">Valid last name is required.</div>
								</div>

								<div class="col-sm-6">
									<label for="lastName" class="form-label">
										State / Province
									</label>
									<input name="state" type="text" class="form-control" id="state" placeholder="" required />
									<div class="invalid-feedback">Valid last name is required.</div>
								</div>

								<div class="col-sm-6">
									<label for="lastName" class="form-label">
										City
									</label>
									<input name="city" type="text" class="form-control" id="city" placeholder="" required />
									<div class="invalid-feedback">Valid last name is required.</div>
								</div>
							</div>

							<h4 class="my-3">Enquiry Detail</h4>

							<textarea
								className="w-100 mb-3 p-4 form-control"
								value={textAreaContent}
								onChange={handleChange}
								name="message"
								id="message"
								cols="50"
								rows="5"
								required
							></textarea>

							<div class="col-12 mb-4 ">
								<label for="email" class="form-label">
									Email <span class="text-body-secondary">(Must)</span>
								</label>
								<input
									name="email"
									type="email"
									class="form-control"
									id="email"
									required
									placeholder="you@example.com"
								/>
								<div class="invalid-feedback">Please enter a valid email address for shipping updates.</div>
							</div>
							<div className="container p-4 bg-body-secondary">
								<h4 className="mx-2 mx-lg-5 py-3 ">
									Below are the common questions asked by other buyers. Click to include them in your enquiry
									details.
								</h4>
								<div className="suggest">
									<ul>
										{listItems.map((item, index) => (
											<li className="btn btn-outline-dark m-2" key={index} onClick={() => handleClick(item)}>
												{item}
											</li>
										))}
									</ul>
								</div>
							</div>

							<button class="w-100 btn mb-5 btn-dark btn-lg" type="submit">
								Continue to checkout
							</button>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default Enquire;
