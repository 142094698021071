import React, { useState } from "react";
import Item from "../Components/Item/Item";
import data from "../Components/Assets/Data/data";
import ReactPaginate from "react-paginate";

const Category = (props) => {
	// Pagination states
	const [currentPage, setCurrentPage] = useState(0);
	const itemsPerPage = 15; // You can adjust the number of items per page

	// Filter items by category
	const filteredItems = data.filter(
		(item) => props.category === "All" || props.category === item.category
	);

	// Paginate the filtered items
	const offset = currentPage * itemsPerPage;
	const currentItems = filteredItems.slice(offset, offset + itemsPerPage);
	const pageCount = Math.ceil(filteredItems.length / itemsPerPage);

	// Handle page click
	const handlePageClick = ({ selected }) => {
		setCurrentPage(selected);
	};

	return (
		<>
			<div className="categories container d-flex flex-wrap gap-1 gap-lg-4 justify-content-center">
				{currentItems.map((item, i) => (
					<Item
						key={i}
						id={item.id}
						name={item.name}
						price={item.price}
						url={item.imageUrl}
						desc={item.description}
						color={item.color}
						button={item.button}
						category={item.category}
					/>
				))}
			</div>

			{/* Pagination */}
			<ReactPaginate
				previousLabel={"Previous"}
				nextLabel={"Next"}
				breakLabel={"..."}
				pageCount={pageCount}
				marginPagesDisplayed={2}
				pageRangeDisplayed={3}
				onPageChange={handlePageClick}
				containerClassName={"pagination"}
				activeClassName={"active"}
			/>
		</>
	);
};

export default Category;
