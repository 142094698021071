import React from "react";
import "../Category/Category.css";
import ct1 from "../Assets/Images/Category/T-shirt.webp";
import ct2 from "../Assets/Images/Category/Printed.webp";
import ct3 from "../Assets/Images/Category/Polo.webp";
import ct4 from "../Assets/Images/Category/Short.webp";
import ct5 from "../Assets/Images/Category/SweatShirt.webp";
import ct6 from "../Assets/Images/Category/Hoodie.webp";
import { Link } from "react-router-dom";

const Category = () => {
	return (
		<>
			<div class="album py-lg-5 py-2 bg-body-tertiary">
				<div class="container">
					<div id="album" class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-0">
						<div class="col mb-lg-0 mb-4">
							<div class="card shadow-sm">
								<img className="rounded-top-2" src={ct1} alt="" />
								<Link to={"/Category/Plain T Shirt"}>
									<button className="btn btn-dark rounded-0 py-1 py-lg-2">T Shirts</button>
								</Link>
							</div>
						</div>
						<div class="col mb-lg-0 mb-4">
							<div class="card shadow-sm">
								<img className="rounded-top-2" src={ct2} alt="" />
								<Link to={"/Category/Printed T Shirt"}>
									<button className="btn btn-dark rounded-0 py-1 py-lg-2">Printed T Shirts</button>
								</Link>
							</div>
						</div>
						<div class="col mb-lg-0 mb-4">
							<div class="card shadow-sm">
								<img className="rounded-top-2" src={ct3} alt="" />
								<Link to={"/Category/Polo T Shirt"}>
									<button className="btn btn-dark rounded-0 py-1 py-lg-2">Polo T Shirts</button>
								</Link>
							</div>
						</div>
						<div class="col mb-lg-0 mb-4">
							<div class="card shadow-sm">
								<img className="rounded-top-2" src={ct4} alt="" />
								<Link to={"/Category/Shorts"}>
									<button className="btn btn-dark rounded-0 py-1 py-lg-2"> Shorts</button>
								</Link>
							</div>
						</div>
						<div class="col mb-lg-0 mb-4">
							<div class="card shadow-sm">
								<img className="rounded-top-2" src={ct5} alt="" />
								<Link to={"/Category/Sweat Shirt"}>
									<button className="btn btn-dark rounded-0 py-1 py-lg-2"> Sweat Shirts</button>
								</Link>
							</div>
						</div>
						<div class="col mb-lg-0 mb-4">
							<div class="card shadow-sm">
								<img className="rounded-top-2" src={ct6} alt="" />
								<Link to={"/Category/Hoodies"}>
									<button className="btn btn-dark rounded-0 py-1 py-lg-2">Hoodies</button>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Category;
