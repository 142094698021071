import React from "react";
import "../Carousel/Carousel.css";
import imgbnr1 from "../Assets/Images/banner 1.webp";
import imgbnr2 from "../Assets/Images/banner 2.webp";
import imgbnr3 from "../Assets/Images/banner 3.webp";

const Carousel = () => {
	return (
		<>
			<div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
				<div id="carousel" class="carousel-inner">
					<div class="carousel-item active" data-bs-interval="10000">
						<img src={imgbnr1} className="d-block w-100 object-fit-cover" alt="..." />
					</div>
					<div class="carousel-item" data-bs-interval="2000">
						<img src={imgbnr2} className="d-block w-100 object-fit-cover" alt="..." />
					</div>
					<div class="carousel-item">
						<img src={imgbnr3} className="d-block w-100 object-fit-cover " alt="..." />
					</div>
				</div>
				<button
					class="carousel-control-prev"
					type="button"
					data-bs-target="#carouselExampleInterval"
					data-bs-slide="prev"
				>
					<span class="carousel-control-prev-icon" aria-hidden="true"></span>
					<span class="visually-hidden">Previous</span>
				</button>
				<button
					class="carousel-control-next"
					type="button"
					data-bs-target="#carouselExampleInterval"
					data-bs-slide="next"
				>
					<span class="carousel-control-next-icon" aria-hidden="true"></span>
					<span class="visually-hidden">Next</span>
				</button>
			</div>
		</>
	);
};

export default Carousel;
