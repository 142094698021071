import React from "react";
import img from "../Assets/Images/wallpaperflare.com_wallpaper.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import "../Brand/Brand.css";

const Brand = () => {
	return (
		<>
			<section class="">
				<div id="about-page" class="container-fluid p-0 d-flex flex-column justify-content-evenly">
					<section>
						<img
							rel="preload"
							onClick={window.scrollTo(0, 0)}
							id="about-img"
							className="w-100 mb-4"
							src={img}
							alt=""
						/>
					</section>
					<div class="About d-flex flex-column gy-3 gy-md-4 gy-lg-0 align-items-lg-center ">
						<div class="col-12 col-lg-8 col-xl-9 ">
							<section className="overflow-hidden">
								{" "}
								<div class="row justify-content-xl-center">
									<div class="col-12 col-xl-11 p-4 p-lg-0 mx-0 mx-lg-0 ">
										<h1 id="About-title" class="mb-4 mt-3 ">
											About Us
										</h1>
										<p class="fs-6 mb-3">
											Beaoxogo is a global leader in the export industry, specializing in delivering
											high-quality products to international markets. With years of experience and a strong
											network of suppliers and partners, we are committed to facilitating seamless trade across
											borders while ensuring the highest standards of service, reliability, and efficiency.
										</p>
										<p class="fs-6 mb-4">
											We are a fast-growing company, but we have never lost sight of our core values. We
											believe in collaboration, innovation, and customer satisfaction. We are always looking
											for new ways to improve our products and services.
										</p>
										<h2 class="mb-3">Our Mission</h2>
										<p class=" fs-6 mb-3">
											Our mission is to empower businesses worldwide by providing access to premium goods and
											services at competitive prices. We aim to foster long-term relationships with our clients
											by ensuring transparency, trust, and excellence in every transaction.
										</p>

										<h2 class="mb-3">What We Do</h2>
										<p class=" fs-6 mb-2 mb-5">
											At Beaoxogo, we export a wide range of products, including Shirts (Casual, printed,
											Polo), Shorts, Sweatshirts, Hoodies, Activewear (Sportswear, Tracksuits), Sports T-Shirts
											and Tanks etc. Our team works closely with local manufacturers and global logistics
											networks to ensure that each shipment is handled with care and arrives on time.
										</p>
										<h2 class="mb-4">Why Choose Us?</h2>
										<ul>
											<li class="fs-6 mb-3">
												<strong>Global Reach:</strong> Our extensive network of partners and logistics providers
												ensures your goods reach markets around the world, quickly and securely.
											</li>
											<li class="fs-6 mb-3">
												<strong>Quality Assurance:</strong> We source products from trusted manufacturers and
												conduct thorough inspections to maintain consistent quality.
											</li>
											<li class="fs-6 mb-3">
												<strong>Expertise:</strong> Our team of export professionals has deep industry knowledge
												and decades of experience in navigating the complexities of international trade.
											</li>
											<li class="fs-6 mb-3">
												<strong>Customer-Centric Approach:</strong> We pride ourselves on offering personalized
												service, helping our clients overcome logistical challenges and customize solutions to
												fit their needs.
											</li>
											<li class="fs-6 mb-4">
												<strong>Compliance & Sustainability:</strong> We adhere to all international trade
												regulations and actively support sustainable practices to reduce our environmental
												impact.
											</li>
										</ul>
										<h2 class="mb-4 mt-5">Our Values</h2>
										<ul>
											<li class="fs-6 mb-3">
												<strong>Integrity:</strong> We believe in honesty and transparency, building trust with
												every client and partner.
											</li>
											<li class="fs-6 mb-3">
												<strong>Commitment to Excellence:</strong> From the products we source to the services
												we provide, we are dedicated to maintaining the highest standards of excellence
											</li>
											<li class="fs-6 mb-4">
												<strong>Innovation:</strong> We continuously adapt to the evolving global trade
												environment, leveraging technology and industry trends to improve efficiency.
											</li>
										</ul>
										<h3 class="mb-4 mt-5 text-center fw-bold ">Get In Touch</h3>
										<p class=" fs-6 mb-2 text-center">
											Whether you are looking to expand your reach into new markets or need assistance with
											finding the right products, Beaoxogo is here to support your international business
											needs. Contact us today to learn more about how we can help you grow.
										</p>
										<div class="container about-icons d-flex justify-content-evenly mb-2 mb-lg-5 p-2 py-lg-4">
											<a
												href="https://wa.me/+923297309631"
												target="_blank"
												class=" nav-link py-3 px-4 bg-black text-white rounded-3 "
											>
												<FontAwesomeIcon icon={faWhatsapp} size="2x" />
											</a>
											<a
												href="mailto:info@beaoxogo.com"
												target="_blank"
												class=" nav-link py-3 px-4 bg-black text-white rounded-3"
											>
												<FontAwesomeIcon icon={faEnvelope} size="2x" />
											</a>
											<a
												href="https://www.instagram.com/beaoxogo?igsh=MTI5dzhmeDFja2s5dA=="
												target="_blank"
												class=" nav-link py-3 px-4 bg-black text-white rounded-3"
											>
												<FontAwesomeIcon icon={faInstagram} size="2x" />
											</a>
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Brand;
