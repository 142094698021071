import React from "react";
import "../Footer/Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faPinterest, faThreads } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
	return (
		<>
			<footer class=" d-flex flex-wrap justify-content-between py-3 ">
				<div className="container d-flex align-items-center justify-content-between footer-container">
					<p class="col-md-4 mb-0 text-white copyright">© 2024 Company, Inc</p>

					<ul class="nav col-md-4 justify-content-end footer-icons">
						<li class="nav-item">
							<a
								href="https://www.instagram.com/beaoxogo?igsh=MTI5dzhmeDFja2s5dA=="
								target="_blank"
								class="text-white nav-link px-2"
							>
								<FontAwesomeIcon icon={faInstagram} size="2x" />
							</a>
						</li>
						<li class="nav-item">
							<a
								href="https://www.pinterest.com/beaoxogo/"
								target="_blank"
								class="text-white nav-link px-2"
							>
								<FontAwesomeIcon icon={faPinterest} size="2x" />
							</a>
						</li>
						<li class="nav-item">
							<a
								href="https://www.threads.net/@beaoxogo?hl=en"
								target="_blank"
								class="text-white nav-link px-2"
							>
								<FontAwesomeIcon icon={faThreads} size="2x" />
							</a>
						</li>
					</ul>
				</div>
			</footer>
		</>
	);
};

export default Footer;
