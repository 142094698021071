import React from "react";
import { Link } from "react-router-dom";
import "../NavBar/Navbar.css";
import icon from "../Assets/Images/brand-icon.webp";

const Navbar = () => {
	const handleNavLinkClick = () => {
		const navbarCollapse = document.getElementById("navbarColor01");
		navbarCollapse.classList.remove("show"); // Close the navbar
	};

	return (
		<nav class="navbar navbar-expand-lg border-body py-3" data-bs-theme="dark">
			<div class="container-fluid">
				<Link
					className="text-decoration-none text-white d-flex align-items-center ms-lg-5 ms-1"
					to={"/"}
				>
					<img className="brand-icon me-2 rounded-2" src={icon} alt="" />
					<h5 className="brand-name">BEAOXOGO</h5>
				</Link>
				<button
					class="navbar-toggler"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#navbarColor01"
					aria-controls="navbarColor01"
					aria-expanded="true"
					aria-label="Toggle navigation"
				>
					<span class="navbar-toggler-icon"></span>
				</button>
				<div class="collapse navbar-collapse" id="navbarColor01">
					<ul class="d-flex justify-content-end w-100 navbar-nav me-5 mb-2 mb-lg-0">
						<li class="nav-item">
							<Link to={"/"} onClick={handleNavLinkClick}>
								Home{" "}
							</Link>
						</li>
						<li class="nav-item">
							<Link to={"/Shop"} onClick={handleNavLinkClick}>
								Shop
							</Link>
						</li>
						<li class="nav-item">
							<Link to={"/Contact"} onClick={handleNavLinkClick}>
								Contact
							</Link>
						</li>
						<li class="nav-item">
							<Link to={"/About"} onClick={handleNavLinkClick}>
								About
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
