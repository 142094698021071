// data.js
const featureProducts = [
	{
		id: 147,
		name: "Men Printed Sweatshirt 12",
		price: "Price : $8.9",
		category: "Sweat Shirt",
		productionLeadTime: "Production Lead Time: 40",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL ",
		Collar: "Collar Type: O-Neck",
		minimunOrderQuantity: "Minimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Winter",
		fabricType: "Fabric Type: Fleece",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Embrace timeless style and everyday comfort with our premium sweatshirt, thoughtfully designed for versatility and performance. Crafted from a superior blend of soft cotton and durable polyester, this sweatshirt offers the ideal combination of warmth and breathability, making it perfect for any occasion—from lounging at home to braving chilly outdoor adventures. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "Soft, durable cotton-polyester blend",
			customDesign: "Relaxed, ergonomic fit for comfort and mobility",
			vibrantPrints: "Soft, High-Quality Fabric for ultimate comfort.",
			unisexFit: "Add your own text, logos, or artwork.",
			multipleColors: "High-quality, durable prints that won’t crack or fade.",
			reinforcedStitching: " Comfortable fit for all body types",
			ecoFriendlyPrinting: " Choose from a variety of color options to match your style.",
			lightweightAndStretchable: "Durable stitching for long-lasting wear.",
			fit: "Uses eco-conscious ink and processes.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Sweat shirt/LLPIC12/main-shirt.webp"),
			require("../Images/Products/Sweat shirt/LLPIC12/6.webp"),
			require("../Images/Products/Sweat shirt/LLPIC12/41.webp"),
			require("../Images/Products/Sweat shirt/LLPIC12/42.webp"),
			require("../Images/Products/Sweat shirt/LLPIC12/43.webp"),
			require("../Images/Products/Sweat shirt/LLPIC12/44.webp"),
		],
		button: "Enquire",
	},
	{
		id: 101,
		name: "Men Cotton Printed T-Shirt 26",
		price: "Price : $5.9",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/26.webp"),
			require("../Images/Products/printed t shirt/26.webp"),
			require("../Images/Products/printed t shirt/26.webp"),
			require("../Images/Products/printed t shirt/26.webp"),
			require("../Images/Products/printed t shirt/26.webp"),
			require("../Images/Products/printed t shirt/26.webp"),
		],
		button: "Enquire",
	},
	{
		id: 148,
		name: "Men Printed Sweatshirt 13",
		price: "Price : $8.6",
		category: "Sweat Shirt",
		productionLeadTime: "Production Lead Time: 40",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL ",
		Collar: "Collar Type: O-Neck",
		minimunOrderQuantity: "Minimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Winter",
		fabricType: "Fabric Type: Fleece",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Embrace timeless style and everyday comfort with our premium sweatshirt, thoughtfully designed for versatility and performance. Crafted from a superior blend of soft cotton and durable polyester, this sweatshirt offers the ideal combination of warmth and breathability, making it perfect for any occasion—from lounging at home to braving chilly outdoor adventures. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "Soft, durable cotton-polyester blend",
			customDesign: "Relaxed, ergonomic fit for comfort and mobility",
			vibrantPrints: "Soft, High-Quality Fabric for ultimate comfort.",
			unisexFit: "Add your own text, logos, or artwork.",
			multipleColors: "High-quality, durable prints that won’t crack or fade.",
			reinforcedStitching: " Comfortable fit for all body types",
			ecoFriendlyPrinting: " Choose from a variety of color options to match your style.",
			lightweightAndStretchable: "Durable stitching for long-lasting wear.",
			fit: "Uses eco-conscious ink and processes.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Sweat shirt/LPIC13/main-shirt.webp"),
			require("../Images/Products/Sweat shirt/LPIC13/1.webp"),
			require("../Images/Products/Sweat shirt/LPIC13/2.webp"),
			require("../Images/Products/Sweat shirt/LPIC13/6.webp"),
			require("../Images/Products/Sweat shirt/LPIC13/8.webp"),
			require("../Images/Products/Sweat shirt/LPIC13/4.webp"),
		],
		button: "Enquire",
	},
	{
		id: 98,
		name: "Men Cotton Printed T-Shirt 23",
		price: "Price : $5.8",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/23.webp"),
			require("../Images/Products/printed t shirt/23.webp"),
			require("../Images/Products/printed t shirt/23.webp"),
			require("../Images/Products/printed t shirt/23.webp"),
			require("../Images/Products/printed t shirt/23.webp"),
			require("../Images/Products/printed t shirt/23.webp"),
		],
		button: "Enquire",
	},
	{
		id: 112,
		name: " Men Printed Kangaroo Hoodie 7",
		price: "Price : $9.6",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/FFFPIC/main-hoody.webp"),
			require("../Images/Products/Hoodie/FFFPIC/2.webp"),
			require("../Images/Products/Hoodie/FFFPIC/3.webp"),
			require("../Images/Products/Hoodie/FFFPIC/4.webp"),
			require("../Images/Products/Hoodie/FFFPIC/6.webp"),
			require("../Images/Products/Hoodie/FFFPIC/7.webp"),
		],
		button: "Enquire",
	},
	{
		id: 150,
		name: "Men Printed Sweatshirt 15",
		price: "Price : $8.7",
		category: "Sweat Shirt",
		productionLeadTime: "Production Lead Time: 40",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL ",
		Collar: "Collar Type: O-Neck",
		minimunOrderQuantity: "Minimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Winter",
		fabricType: "Fabric Type: Fleece",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Embrace timeless style and everyday comfort with our premium sweatshirt, thoughtfully designed for versatility and performance. Crafted from a superior blend of soft cotton and durable polyester, this sweatshirt offers the ideal combination of warmth and breathability, making it perfect for any occasion—from lounging at home to braving chilly outdoor adventures. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "Soft, durable cotton-polyester blend",
			customDesign: "Relaxed, ergonomic fit for comfort and mobility",
			vibrantPrints: "Soft, High-Quality Fabric for ultimate comfort.",
			unisexFit: "Add your own text, logos, or artwork.",
			multipleColors: "High-quality, durable prints that won’t crack or fade.",
			reinforcedStitching: " Comfortable fit for all body types",
			ecoFriendlyPrinting: " Choose from a variety of color options to match your style.",
			lightweightAndStretchable: "Durable stitching for long-lasting wear.",
			fit: "Uses eco-conscious ink and processes.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Sweat shirt/NNPIC15/main-shirt.webp"),
			require("../Images/Products/Sweat shirt/NNPIC15/9.webp"),
			require("../Images/Products/Sweat shirt/NNPIC15/8.webp"),
			require("../Images/Products/Sweat shirt/NNPIC15/10.webp"),
			require("../Images/Products/Sweat shirt/NNPIC15/11.webp"),
			require("../Images/Products/Sweat shirt/NNPIC15/12.webp"),
		],
		button: "Enquire",
	},
	{
		id: 108,
		name: " Men Printed Kangaroo Hoodie 3",
		price: "Price : $9.4",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/DDDPIC/main-hoody.webp"),
			require("../Images/Products/Hoodie/DDDPIC/2.webp"),
			require("../Images/Products/Hoodie/DDDPIC/4.webp"),
			require("../Images/Products/Hoodie/DDDPIC/5.webp"),
			require("../Images/Products/Hoodie/DDDPIC/6.webp"),
			require("../Images/Products/Hoodie/DDDPIC/7.webp"),
		],
		button: "Enquire",
	},
];

export default featureProducts;
