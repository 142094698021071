import React, { useEffect, useState } from "react";
import "../Detail/Detail.css";
import { Link } from "react-router-dom";

const Detail = (props) => {
	const { productData } = props;

	const [mainImage, setMainImage] = useState(productData.imageUrl[0]);

	useEffect(() => {
		setMainImage(productData.imageUrl[0]); // Reset to the first image when new product is loaded
	}, [productData]);

	const handleThumbnailClick = (imageUrl) => {
		setMainImage(imageUrl);
	};

	return (
		<>
			<div className="detail-page container d-flex display justify-content-center">
				<div className="left d-flex justify-content-center align-items-center mb-2">
					<div className="min-img d-flex ">
						{productData.imageUrl.map((image, index) => (
							<img
								rel="preload"
								loading="lazy"
								key={index}
								src={image}
								q
								alt={`Thumbnail ${index}`}
								onClick={() => {
									handleThumbnailClick(image); // Update main image
								}}
								className="thumbnail"
							/>
						))}
					</div>

					<img
						className="p-4 p-lg-5"
						loading="lazy"
						src={mainImage} // Display the currently selected main image
						alt={productData.name}
					/>
				</div>
				<hr />

				<div className="right d-flex justify-content-center align-items-left flex-column py-2 py-lg-5">
					<h3>{productData.name}</h3>
					{productData.category && <p>{productData.category}</p>}
					{productData.productionLeadTime && <p>{productData.productionLeadTime}</p>}
					{productData.Sizes && <p>{productData.Sizes}</p>}
					{productData.Collar && <p>{productData.Collar}</p>}
					{productData.minimunOrderQuantity && <p>{productData.minimunOrderQuantity}</p>}
					{productData.Technics && <p>{productData.Technics}</p>}
					{productData.Season && <p>{productData.Season}</p>}
					{productData.fabricType && <p>{productData.fabricType}</p>}
					{productData.Material && <p>{productData.Material}</p>}
					{productData.Customised && <p>{productData.Customised}</p>}

					<Link to={`/EnquirePage/${productData.id}`}>
						<button className="btn btn-dark w-50">{productData.button}</button>
					</Link>
				</div>
			</div>
			<div class="accordion container mt-5" id="accordionExample">
				<div class="accordion-item">
					<h2 class="accordion-header">
						<button
							class="accordion-button"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseOne"
							aria-expanded="true"
							aria-controls="collapseOne"
						>
							DESCRIPTION
						</button>
					</h2>
					<div
						id="collapseOne"
						class="accordion-collapse collapse show"
						data-bs-parent="#accordionExample"
					>
						<div class="accordion-body">
							<p className="mb-4">{productData.description}</p>
							<hr />
							{productData.Feature &&
								Object.keys(productData.Feature).map(
									(key) => productData.Feature[key] && <li key={key}>{productData.Feature[key]}</li>
								)}
							<p className="mt-4">
								<strong>Custom Label Option:</strong> Option to add custom branding inside for businesses
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Detail;
