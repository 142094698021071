import React from "react";
import "../Hero/Hero.css";
import heroimg from "../Assets/Images/Hero-Banner-2.webp";
import { Link } from "react-router-dom";

const Hero = () => {
	return (
		<>
			<div class="overflow-hidden Hero">
				<img rel="preload" src={heroimg} alt="" />
				<div class="d-flex justify-content-center align-items-center ">
					<div class="content mt-3 d-flex flex-column justify-content-start  ">
						<h1 class="display-4 fw-bold lh-1  mb-4 text-center ">
							Clothing Manufacturer <br /> and Stock Supplier
						</h1>
					</div>
				</div>
			</div>
		</>
	);
};

export default Hero;
