import React from "react";
import "../Form/Form.css";

const Form = () => {
	return (
		<>
			<div class="container col-xl-10 col-xxl-8 px-4 py-2 py-lg-5">
				<div class="row align-items-center g-lg-5 py-lg-5 py-4">
					<div class="col-lg-7 text-center text-lg-start mt-0">
						<h1 class="display-4 fw-bold lh-1 text-body-emphasis mb-3">
							Stay in the Loop, Never Miss an Update from Us!
						</h1>
						<p id="form-text" class="col-lg-10 fs-4">
							Be the first to know about our latest news, updates, and exclusive offers. Stay connected and
							never miss out on what’s new and exciting!
						</p>
					</div>
					<div class="col-md-10 mx-auto my-lg-0 my-4 col-lg-5">
						<form
							action="https://getform.io/f/ajjjdrqa"
							method="POST"
							class="p-4 p-md-5 border rounded-3 bg-body-tertiary"
						>
							<div class="form-floating mb-3">
								<input
									name="name"
									required
									type="text"
									class="form-control"
									id="floatingInput"
									placeholder="Name"
								/>
								<label for="floatingInput">Name</label>
							</div>
							<div class="form-floating mb-3">
								<input
									name="email"
									required
									type="email"
									class="form-control"
									id="floatingInput2"
									placeholder="Email"
								/>
								<label for="floatingInput2">Email</label>
							</div>

							<button class="w-100 btn btn-lg btn-primary" type="submit">
								Sign Up
							</button>
							<hr class="my-4" />
							<p class="text-body-secondary text-center">
								By clicking Sign up, you agree to the terms of use.
							</p>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default Form;
